import {AsyncAction} from 'overmind'
import {MultipleSiteSuspendedPeriodPayload, MultipleSiteSuspendedPeriodResponse} from './state'

export const newMultipleSiteSuspendedPeriod: AsyncAction<
  MultipleSiteSuspendedPeriodPayload,
  MultipleSiteSuspendedPeriodResponse | undefined
> = async ({state, effects}, params: MultipleSiteSuspendedPeriodPayload) => {
  try {
    const res = await effects.v1.settings.multipleSiteSuspendedPeriod.multipleSiteSuspendedPeriodsApi.newMultipleSiteSuspendedPeriod(
      params
    )
    return res
  } catch (err) {
    state.v1.settings.multipleSiteSuspendedPeriod.error = err as Error
  }
}
