import React, {useState} from 'react'
import {useTranslation} from 'react-i18next'
import {Button, Input} from '../../../sharedComponents/components'
import {colors} from '../../../sharedComponents/colors'

import styled from 'styled-components'

import {AssetData} from '../../../state/rest'
import {useAppState} from '../../../state'
import AssetUploaderSmall from '../../../Components/Molecules/UploadImage'
import {SmartAsset} from '../../../Components/Atoms/ImageLoader'
import InputWithTags from './InputWithTags'
import {LabelCheckbox} from '../../../Components/Atoms/Forms'

interface PerformTaskFormProps {
  onInputChange: (key: 'remarks' | 'reportedActor', value: string) => void
  payload: any
  handleRemove?: () => void
  onAssetSaved?: (asset?: AssetData) => void
  setValue: any
  confirm?: string
}

/**
 * Component for displaying elements that are same independent of task type
 */

const PerformTaskForm = ({
  onAssetSaved,
  payload,
  onInputChange,
  handleRemove,
  setValue,
  confirm
}: PerformTaskFormProps) => {
  const {t} = useTranslation(['tasks', 'common'])
  const {task, reportedActor, asset, remarks} = payload
  const {state} = useAppState()
  const [valid, setValid] = useState(!!!confirm)

  // ONLY allow user who is not basic user to remove task: site manager, chain manager, admin
  const allowRemoveTask = state.me && state.site && state.me.accessRights.write.sites.includes(state.site.id)

  return (
    <PerformTaskFormContainer>
      {!task.actorSuggestions ? (
        <Input
          data-cy="task-modal-input-completed-by"
          name={t('tasks:labels.columnNameCompletedBy', 'Completed By')}
          label={t('tasks:labels.columnNameCompletedBy', 'Completed By')}
          value={reportedActor || task.reportedActor || ''}
          onChange={(e: any) => onInputChange('reportedActor', e.target.value)}
          placeholder={t('tasks:labels.completedBy.placeholder', 'Name')}
        />
      ) : (
        <InputWithTags
          label={t('tasks:labels.columnNameCompletedBy', 'Completed By')}
          placeholder={t('tasks:labels.completedBy.placeholder', 'Name')}
          value={task.reportedActor || ''}
          defaultSuggestions={task.actorSuggestions || []}
          onChange={setValue}
          keyName="reportedActor"
        />
      )}
      <Input
        name="remarks"
        label={t('tasks:labels.otherInformation', 'Other Information')}
        value={remarks || ''}
        multiline
        onChange={(e: any) => onInputChange('remarks', e.target.value)}
        placeholder={t('tasks:labels.writeNotes', 'Add notes about the task')}
      />
      {!!onAssetSaved && <AssetUploaderSmall onAssetSaved={onAssetSaved} savedAsset={asset} />}
      {!!asset && <SmartAsset asset={asset as any} />}
      {!!handleRemove && allowRemoveTask && (
        <div>
          <Divider />
          {!!confirm && (
            <LabelCheckbox
              flipped={false}
              checked={valid}
              onClick={() => setValid(s => !s)}
              label={confirm}
              name="confirmModal"
            />
          )}
          <RemoveTaskArea>
            <DangerousButton
              onClick={() => handleRemove()}
              data-testid="reject-button"
              data-cy="perform-task-remove"
              buttonSize="small"
              disabled={!valid}
            >
              {t('common:buttons.removeTask', 'Remove task')}
            </DangerousButton>
          </RemoveTaskArea>
        </div>
      )}
    </PerformTaskFormContainer>
  )
}

const PerformTaskFormContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: 1rem;
`

const RemoveTaskArea = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-end;
  gap: 1rem;
`

const Divider = styled.hr`
  width: 100%;
  height: 1px;
  background-color: ${colors.system.grey_5};
  border: none;
`

const DangerousButton = styled(Button)`
  color: red;
  background-color: white;
  border-color: red;
`

export default PerformTaskForm
