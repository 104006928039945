export const websocketUrl = process.env.REACT_APP_WEBSOCKET_URL
export const pingIntervalMs = 60000
export const reconnectIntervalMs = 10000

export enum WebsocketMessageType {
  alarm = 'alarm',
  cycleLog = 'cycleLog',
  event = 'event',
  newsTicker = 'newsTicker',
  task = 'task',
  ping = 'ping',
  pong = 'pong'
}

export type WebsocketMessage = {
  type: WebsocketMessageType
  siteId?: string
}

class WebsocketClient {
  public onConnect: ((event: Event) => void) | null = null
  public onDisconnect: ((event: CloseEvent) => void) | null = null
  public onMessage: ((event: MessageEvent) => void) | null = null
  public onError: ((event: Event) => void) | null = null
  public onConnectionLost: (() => void) | null = null

  private client: WebSocket | null = null
  private idToken: string | null = null
  private pingTaskId: number | null = null
  private reconnectTaskId: number | null = null
  private autoReconnect: boolean = true

  public setIdToken = (idToken: string) => {
    this.disconnect()
    this.idToken = idToken
  }

  public connect = (autoReconnect: boolean = true) => {
    if (!websocketUrl) {
      throw new Error('Environment variable REACT_APP_WEBSOCKET_URL not set')
    }
    if (!this.idToken) {
      throw new Error('ID token not set')
    }
    this.disconnect()
    this.autoReconnect = autoReconnect
    this.client = new WebSocket(`${websocketUrl}?x-chefstein-token=${this.idToken}`)
    this.client.onopen = this.handleOpen
    this.client.onclose = this.handleClose
    this.client.onmessage = this.handleMessage
    this.client.onerror = this.handleError
  }

  public disconnect = (autoReconnect: boolean = true) => {
    if (this.client?.readyState === WebSocket.OPEN || this.client?.readyState === WebSocket.CONNECTING) {
      this.autoReconnect = autoReconnect
      this.client.close()
    }
  }

  public stopEverything = () => {
    console.warn('Stopping all WebSocket activity (including auto-reconnect)')
    this.stopPingTask()
    this.stopReconnectTask()
    this.autoReconnect = false // Turn off auto-reconnect
    this.client?.close() // Make sure connection is closed
    this.client = null
  }

  private send = (message: WebsocketMessage) => {
    if (this.client?.readyState === WebSocket.OPEN) {
      this.client?.send(JSON.stringify(message))
    }
  }

  private handleOpen = (event: Event) => {
    console.log('Websocket connected')
    this.stopReconnectTask()
    this.startPingTask()
    this.onConnect?.(event)
  }

  private startPingTask = () => {
    this.stopPingTask()
    this.pingTaskId = window.setInterval(() => {
      this.send({type: WebsocketMessageType.ping})
    }, pingIntervalMs)
  }

  private stopPingTask = () => {
    if (typeof this.pingTaskId === 'number') {
      window.clearInterval(this.pingTaskId)
      this.pingTaskId = null
    }
  }

  private handleClose = (event: CloseEvent) => {
    console.log('Websocket disconnected', `code: ${event.code}`, `reason: ${event.reason}`)
    if (event.code === 1006) {
      console.warn('Websocket disconnected with code 1006 - treating as fatal, stopping reconnect')
      this.stopEverything()
      this.onConnectionLost?.() // Trigger the error toast (you set this from the outside)
      return // Don't start reconnect after fatal error
    }
    this.startReconnectTask()
    this.onDisconnect?.(event)
  }

  private startReconnectTask = () => {
    this.stopReconnectTask()
    if (this.autoReconnect) {
      this.reconnectTaskId = window.setInterval(() => {
        if (this.client?.readyState === WebSocket.CLOSED) {
          this.connect()
        }
      }, reconnectIntervalMs)
    }
  }

  private stopReconnectTask = () => {
    if (typeof this.reconnectTaskId === 'number') {
      window.clearInterval(this.reconnectTaskId)
      this.reconnectTaskId = null
    }
  }

  private handleMessage = (event: MessageEvent) => {
    this.onMessage?.(event)
  }

  private handleError = (event: Event) => {
    console.error('Websocket error', event)
    this.onError?.(event)
  }
}

export const websocketClient = new WebsocketClient()
