import React from 'react'
import {useTranslation} from 'react-i18next'
import {Baseline, Grid, Row, InvisibleContainer, GridSpan} from '../../Layout/Grid'
import {LabelInput, Select, Toggler, BigLabelInput} from '../../Atoms/Forms'
import {DefaultTagsWidget} from '../../Organisms/Tags/DefaultSuggestionWidget'
import {Button, ButtonRowWrap, ButtonRowGrid} from '../../Atoms/Buttons'
import Scheduler from '../../Organisms/Scheduler/Scheduler'
import {MainLayoutWithoutStretch, RightSidebar, MainContent} from '../../Layout/Layout'
import LocationsList from '../../Organisms/Locations/LocationsList'
import {
  useTaskForm,
  handleFormNumber,
  useTaskGroupSelect,
  useMeasurementMethodSelect,
  useRestaurantList,
  reverseConvertUnit,
  handleTaskTempValues,
  tempUnitStr,
  valueExists
} from '../../../config/utils'
import {CoolingTaskResponse} from '../../../state/rest'
import {useAppState} from '../../../state'
import {SmallUnit, P} from '../../Atoms/Typography'
import {HeaderRow, HeaderActions} from '../../Molecules/ViewComponents/ViewHeader'
import TaskMenuModal from './TaskMenuModal'
import {CommonInformationFields, TasksGoBackHeaderTitle} from './CommonTasksComponents'

interface FormProps {
  savedData?: CoolingTaskResponse
}
const TASK_KEY = 'cooling'

const CreateCoolingTask = ({savedData}: FormProps) => {
  const {t} = useTranslation('tasks')
  const {state} = useAppState()

  const isEditing = !!savedData && !!savedData.id
  const isDuplicate = !!savedData && !savedData.id

  const saved = savedData
    ? handleTaskTempValues(
        isDuplicate && savedData.name
          ? {
              ...savedData,
              name: `${t('tasks:labels.copyOf', 'Copy of')} ${savedData.name.toLowerCase()}`,
              sites: [state.site!]
            }
          : savedData,
        state.site!
      )
    : undefined
  // task form controller hook
  const fc = useTaskForm(TASK_KEY, saved)
  const rc = useRestaurantList(isDuplicate ? undefined : saved ? saved.sites : undefined)

  const {taskGroupOptions, selectTaskGroup, selectedTaskGroup} = useTaskGroupSelect(
    TASK_KEY,
    saved ? saved.taskGroup : undefined
  )
  const {selectMethod, selectedMeasurementMethod, measurementOptions} = useMeasurementMethodSelect(
    'device',
    saved ? saved.measurementMethod : undefined
  )

  const {minValue, maxValue, timeLimit} = fc.form

  const taskPayload = {
    minValue: reverseConvertUnit(state.site!, handleFormNumber(minValue)),
    maxValue: reverseConvertUnit(state.site!, handleFormNumber(maxValue)),
    defaultSuggestions: fc.defaultSuggestions,
    taskGroup: selectedTaskGroup,
    measurementMethod: selectedMeasurementMethod,
    timeLimit: handleFormNumber(timeLimit),
    schedule: fc.scheduledPayload,
    unit: state.site!.temperatureUnit,
    sites: rc.list,
    assets: fc.assets,
    lateByDays: fc.lateByDays
  }

  const isFormValid = () => {
    const isNameValid = !!fc.form.name
    const isTimeLimitValid = valueExists(fc.form.timeLimit) ? fc.form.timeLimit! > 2 : false
    const minValue = fc.form.minValue
    const maxValue = fc.form.maxValue
    const isMinValueValid =
      valueExists(minValue) &&
      valueExists(maxValue) &&
      parseFloat(minValue!.toString()) < parseFloat(maxValue!.toString())

    return isNameValid && isTimeLimitValid && isMinValueValid
  }

  return (
    <MainLayoutWithoutStretch>
      <MainContent variant="white">
        <HeaderRow
          modal={
            isEditing && (
              <TaskMenuModal
                isOpen={fc.modalOpen}
                onClose={() => fc.setModalOpen(false)}
                onDelete={fc.editingDisabled ? undefined : fc.remove}
                onDuplicate={fc.duplicate}
              />
            )
          }
        >
          <TasksGoBackHeaderTitle
            label={
              isEditing
                ? t('tasks:labels.editTask.cooling', 'Edit cooling task')
                : t('tasks:labels.newTask.cooling', 'Add cooling task')
            }
            isEditing={isEditing}
          />
          {isEditing && <HeaderActions onMenu={() => fc.setModalOpen(true)} />}
        </HeaderRow>
        <InvisibleContainer>
          <Baseline>
            <Grid>
              <BigLabelInput
                required
                placeholder={t('tasks:placeholders.newTaskName', 'New task name')}
                name="name"
                id="name"
                labelText={t('tasks:labels.taskName', 'Task name')}
                ref={fc.register({
                  required: `${t('common:validation.nameRequired', 'Name field is required')}`
                })}
                errorMsg={!!fc.errors.name ? fc.errors.name!.message : undefined}
              ></BigLabelInput>
              <div className="empty"></div>
            </Grid>
            <Grid>
              <Baseline>
                <Select
                  returnId
                  nativeProps={{
                    name: 'taskGroup',
                    onChange(e) {
                      selectTaskGroup(e.target.value)
                    },
                    value: selectedTaskGroup.id
                  }}
                  id="taskGroup"
                  label={t('tasks:labels.taskGroup', 'Task group')}
                  options={taskGroupOptions}
                />
                <Select
                  returnId
                  nativeProps={{
                    name: 'measurementMethod',
                    onChange(e) {
                      selectMethod(measurementOptions.find(mo => mo.id === e.target.value)!.id)
                    },
                    value: selectedMeasurementMethod.id
                  }}
                  id="measurementMethod"
                  label={t('tasks:labels.measurementMethod', 'Measurement method')}
                  options={measurementOptions}
                />
                <Row childMargin="1rem" style={{alignItems: 'flex-start'}}>
                  <LabelInput
                    type="number"
                    ref={fc.register({
                      required: t('common:validation.requiredField', 'This field is required') as string,
                      validate: value =>
                        parseFloat(value) > 2 ||
                        (t('common:validation.valueMustBeAbove2', 'Value must be more than 2') as string)
                    })}
                    name="timeLimit"
                    labelText={t('tasks:labels.duration', 'Duration')}
                    errorMsg={fc.errors.timeLimit?.message}
                  />
                  <SmallUnit>min</SmallUnit>
                  <LabelInput
                    required
                    type="number"
                    ref={fc.register({
                      required: t('common:validation.requiredField', 'This field is required') as string,
                      validate: value =>
                        (valueExists(minValue) ? parseFloat(value) > minValue! : true) ||
                        (t('common:validation.coolingValueTooLow', 'Value must be higher than the end value') as string)
                    })}
                    errorMsg={fc.errors.maxValue?.message}
                    name="maxValue"
                    labelText={t('tasks:labels.startLimit', ' Start limit')}
                  />
                  <LabelInput
                    required
                    type="number"
                    ref={fc.register({
                      required: t('common:validation.requiredField', 'This field is required') as string,
                      validate: value =>
                        (valueExists(maxValue) ? parseFloat(value) < maxValue! : true) ||
                        (t(
                          'common:validation.coolingValueTooHigh',
                          'Value must be lower than the start value'
                        ) as string)
                    })}
                    name="minValue"
                    labelText={t('tasks:labels.endLimit', 'End limit')}
                    errorMsg={fc.errors.minValue?.message}
                  />
                  <SmallUnit>{tempUnitStr(state.site!.temperatureUnit)}</SmallUnit>
                </Row>
                <Toggler
                  label={t('tasks:labels.scheduledTask', 'Scheduled task')}
                  values={[t('common:togglerValues.no', 'No'), t('common:togglerValues.yes', 'Yes')]}
                  initiallyChecked={!!(saved && saved.schedule)}
                  name="isScheduledTask"
                  onClick={() => {
                    fc.toggleShow()
                  }}
                />
                {!!fc.scheduledPayload ? (
                  <Scheduler
                    onChange={fc.handleScheduler}
                    schedule={saved ? fc.reverseSchedule(saved.schedule) : undefined}
                    lateByDays={saved ? fc.lateByDays : undefined}
                    onLateByDaysChange={fc.setLateByDays}
                  />
                ) : null}
              </Baseline>

              <Baseline>
                <CommonInformationFields useForm={fc} />
                <DefaultTagsWidget
                  onChange={items => fc.setDefaultSuggestions(items)}
                  savedItems={saved ? saved.defaultSuggestions : undefined}
                  title={t(
                    'tasks:labels.defaultSuggestionTitle',
                    'Define default suggestions for what user is measuring. These suggestions are shown when completing the task.'
                  )}
                />
              </Baseline>
            </Grid>
            <ButtonRowWrap>
              <div className="empty"></div>
              <ButtonRowGrid>
                <Button name="cancel-button" negative variant="secondary" disabled={fc.loading} onClick={fc.cancel}>
                  {t('common:buttons.cancel', 'Cancel')}
                </Button>
                <Button
                  variant="secondary"
                  disabled={!isFormValid() || fc.loading || rc.list.length === 0 || fc.editingDisabled}
                  onClick={() => (isEditing ? fc.update(taskPayload) : fc.submit(taskPayload))}
                >
                  {t('common:buttons.save', 'Save')}
                </Button>

                {fc.editingDisabled && (
                  <GridSpan span={2}>
                    <P>
                      {t(
                        'tasks:messages.taskInUseMessage',
                        'This task is in use for locations you don’t have access to. You can not edit or delete it but you can duplicate the task to create a new one'
                      )}
                    </P>
                  </GridSpan>
                )}
              </ButtonRowGrid>
            </ButtonRowWrap>
          </Baseline>
        </InvisibleContainer>
      </MainContent>
      <RightSidebar>
        <LocationsList
          controller={rc}
          cyKey={'task'}
          label={t('tasks:labels.selectLocation', 'Select which locations use this task')}
        />
      </RightSidebar>
    </MainLayoutWithoutStretch>
  )
}

export default CreateCoolingTask
