export interface MultipleSiteSuspendedPeriodPayload {
  startAt: string
  endAt: string
  alarmsEnabled: boolean
  reason: string
  sites: string[]
}

export interface OverlappingPeriodInfo {
  siteId: string
  siteName: string
  hasOverlappingPeriod: boolean
}

export interface MultipleSiteSuspendedPeriodResponse {
  status: boolean
  data: OverlappingPeriodInfo[]
}

export interface MultipleSiteSuspendedPeriod {
  id: string
  sites: string[]
  actor: string
  createdAt: Date
  startAt: Date
  endAt: Date
  alarmsEnabled: boolean
  reason: string
}

interface MultipleSiteSuspendedPeriodsState {
  multipleSiteSuspendedPeriods: MultipleSiteSuspendedPeriod[] | null
  error: Error | null
  loading: boolean | null
}

export const state: MultipleSiteSuspendedPeriodsState = {
  multipleSiteSuspendedPeriods: null,
  error: null,
  loading: null
}
