import {baseApi} from '../../rest'
import {MultipleSiteSuspendedPeriodPayload, MultipleSiteSuspendedPeriodResponse} from './state'

export const multipleSiteSuspendedPeriodsApi = {
  ...baseApi,
  async newMultipleSiteSuspendedPeriod(payload: MultipleSiteSuspendedPeriodPayload) {
    const req = await this.doRequest<any>({
      path: `/v1/multipleSiteSuspendedPeriods/multipleSiteSuspendedPeriod`,
      payload
    })
    const resp = (await req.post()) as unknown
    return resp as MultipleSiteSuspendedPeriodResponse
  }
}
