import { Amplify } from 'aws-amplify'
import * as AmplifyAuth from 'aws-amplify/auth'

export enum SignInStepOptions {
  CONFIRM_SIGN_IN_WITH_SMS_CODE = 'CONFIRM_SIGN_IN_WITH_SMS_CODE',
  CONTINUE_SIGN_IN_WITH_MFA_SELECTION = 'CONTINUE_SIGN_IN_WITH_MFA_SELECTION',
  CONFIRM_SIGN_IN_WITH_NEW_PASSWORD_REQUIRED = 'CONFIRM_SIGN_IN_WITH_NEW_PASSWORD_REQUIRED',
}

/**
 * authentication effects
 */

const UserPoolId = process.env.REACT_APP_COGNITO_USERPOOL
const ClientId = process.env.REACT_APP_COGNITO_CLIENTID
const AwsRegion = process.env.REACT_APP_AWS_REGION


Amplify.configure({
  Auth:{
    Cognito:{
      userPoolId: UserPoolId!,
      userPoolClientId: ClientId!,
    }
  }
})



const login = async (email: string, password: string) => {
  try{

    const {isSignedIn, nextStep} =  await AmplifyAuth.signIn({
      username: email,
      password: password
    })
    return {isSignedIn, nextStep}
   }
    catch(err) {
      if((err as Error).name === 'UserAlreadyAuthenticatedException') {
        console.log(
          'User is already authenticated, attempting to sign out and get current user again'
        )
        await AmplifyAuth.signOut()
      }
      throw err
}
}


const confirmSignIn = async (challengeResponse: string, userAttributes?: any) => {
  try {
    const confirmSignInObject = userAttributes ? {challengeResponse: challengeResponse, options: {
      userAttributes: {
        email: userAttributes.email,
        password: userAttributes.password,
      }
    }
  }: {challengeResponse: challengeResponse}
    return AmplifyAuth.confirmSignIn(confirmSignInObject) as Promise<AmplifyAuth.ConfirmSignInOutput>
  }catch(err) {
    throw err
  }
}

const getToken = async (refresh?: boolean) => {
  try {
    const { accessToken, idToken } = (await AmplifyAuth.fetchAuthSession({forceRefresh: refresh??undefined})).tokens ?? {};
    return { accessToken, idToken }
  } catch (err) {
    console.log(err);
  }
}
const getCurrentUser = async () => {
  try {
    const { username, userId, signInDetails } = await AmplifyAuth.getCurrentUser();

    return {username, userId, signInDetails}
  } catch (err) {
    console.log(err);
  }
}
const logout = async () => await AmplifyAuth.signOut()


const completeForceResetPassword = async (
  newPassword: string,
  email: string,
  confirmationCode: string
): Promise<void> => {
  await AmplifyAuth.confirmResetPassword({
    username: email,
    newPassword: newPassword,
    confirmationCode: confirmationCode,   
  })
}

const forgotPassword = async (username: string) => {
  return await AmplifyAuth.resetPassword({username})
}

const confirmResetPassword = async (username: string, newPassword: string, confirmationCode: string) => {
   await AmplifyAuth.confirmResetPassword({username, newPassword, confirmationCode})
}

const updatePassword = async (oldPassword: string, newPassword: string) => {
  return await AmplifyAuth.updatePassword({oldPassword, newPassword})
}



const authApi = {
  login,
  getToken,
  confirmSignIn,
  logout,
  forgotPassword,
  getCurrentUser,
  confirmResetPassword,
  updatePassword,
  completeForceResetPassword,
}

export default authApi
