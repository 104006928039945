import {useTranslation} from 'react-i18next'
import {Chain} from './../state/state'
import {useAppState} from '../state'
import {useNavigation} from 'react-navi'
import {
  TaskTypeKey,
  TaskGroup,
  PossibleFormFields,
  AllTasks,
  AnyTaskPayload,
  PossibleEvent,
  AssetData,
  AnyTaskData,
  AssetType,
  MeasurementMethod,
  FixedEquipmentTypes
} from '../state/rest'
import {useCallback, useState, useRef, useEffect} from 'react'
import {useForm} from 'react-hook-form'
import {useScheduler} from '../Components/Organisms/Scheduler/useScheduler'
import {Site, Location, OrgLevel, OrgLevelType, LocationGroup} from '../state/state'
import {includes, set} from 'lodash'
import ky from 'ky'
import {getWasteUnit} from '../Components/Views/Tasks/CreateWasteTask'
import i18n from 'i18next'
import {DocumentFolder} from '../state/documents/state'
import {CompletedTask, TaskListItemData} from '../state/home/site/state'

export const hasCoolingEventTarget = (taskType: any, suggestions: any[]) => {
  if (taskType && suggestions) {
    return ['cooling', 'automaticCooling'].includes(taskType.id) && suggestions.length > 0
  } else {
    return false
  }
}

export function valueExists(value?: number | string | null) {
  if (value === undefined) {
    return false
  }
  if (value === null) {
    return false
  }
  if (typeof value === 'number' && isNaN(value)) {
    return false
  }
  if (typeof value === 'string' && value.length === 0) {
    return false
  }
  return true
}
export class NumberParser {
  _locale: string
  constructor(locale: string) {
    this._locale = locale
  }
  parse(str: string) {
    const decimal = (1.1).toLocaleString(this._locale).replace(/\d/g, '')
    const group = (1234).toLocaleString(this._locale).replace(/\d/g, '')
    const parsed = str
      .replace(decimal, '.')
      .replace(group, '')
      .trim()

    return valueExists(parsed) ? parseFloat(parsed) : NaN
  }
}
function parseLinks(saved?: Partial<AllTasks>) {
  const firstLink =
    !!saved && !!saved.links && !!saved.links.length
      ? {linkDescription: saved.links[0].description, linkUrl: saved.links[0].url}
      : {}
  return {...saved, ...firstLink}
}
/**
 * A Controller hook for task creation forms
 */
export function useTaskForm(key: TaskTypeKey, saved?: Partial<AllTasks>, isNew?: boolean) {
  const {state, actions} = useAppState()
  const [modalOpen, setModalOpen] = useState(false)

  const backPath = !saved || isNew ? '/create/task' : '/edit/tasks'
  const completedPath = '/edit/tasks'

  const {navigate} = useNavigation()

  async function goBack() {
    navigate(completedPath)
  }
  const {reset, register, watch, errors, triggerValidation, formState, setValue} = useForm<PossibleFormFields>({
    defaultValues: parseLinks(saved),
    mode: 'onChange'
  })

  const [loading, setLoading] = useState(false)
  const form = watch()

  const {scheduledPayload, toggleShow, handleScheduler, reverseSchedule} = useScheduler(
    saved ? saved.schedule : undefined
  )

  const [lateByDays, setLateByDays] = useState(saved && saved.lateByDays ? saved.lateByDays : 1)

  const [automaticReminder, setAutomaticReminder] = useState(
    saved && saved.automaticReminder !== undefined ? saved.automaticReminder : false
  )

  const [defaultSuggestions, setDefaultSuggestions] = useState(saved ? saved.defaultSuggestions : [])
  // this is weird, but works. See https://github.com/facebook/react/issues/14981
  const [, forceError] = useState()
  const submit = (payload: any) => {
    setLoading(true)

    triggerValidation().then(ok => {
      if (ok) {
        actions
          .createTask({
            ...payload,
            name: form.name,
            description: form.description,
            instructions: form.instructions,
            taskType: {id: key},
            automaticReminder: automaticReminder,
            lateByDays: lateByDays,
            schedule: scheduledPayload,
            alarmsEnabled: showAlarm,
            taskGroup: payload.taskGroup ? {id: payload.taskGroup.id} : undefined,
            measurementMethod: payload.measurementMethod ? {id: payload.measurementMethod.id} : undefined,
            links:
              form.linkUrl && form.linkDescription ? [{url: form.linkUrl, description: form.linkDescription}] : null
          })
          .then(goBack)
          .catch(e =>
            forceError(() => {
              throw new LayoutError(
                'We could not create the task. Please check your inputs for mistakes and missing or invalid values'
              )
            })
          )
      } else console.log('errors', {errors})
    })
  }
  const update = (payload: any) => {
    setLoading(true)
    actions
      .updateTask({
        ...payload,
        id: saved!.id!,
        name: form.name,
        description: form.description,
        instructions: form.instructions,
        automaticReminder: automaticReminder,
        taskType: {id: key},
        schedule: scheduledPayload,
        taskGroup: payload.taskGroup ? {id: payload.taskGroup.id} : undefined,
        measurementMethod: payload.measurementMethod ? {id: payload.measurementMethod.id} : undefined,
        links: form.linkUrl && form.linkDescription ? [{url: form.linkUrl, description: form.linkDescription}] : null,
        alarmsEnabled: showAlarm,
        lateByDays: lateByDays
      })
      .then(goBack)
      .catch(e =>
        forceError(() => {
          throw new LayoutError(
            'We could not update the task. Please check your inputs for mistakes and missing or invalid values'
          )
        })
      )
  }

  const remove = () => {
    setLoading(true)

    actions
      .deleteTask(saved!.id!)
      .then(goBack)
      .catch(e =>
        forceError(() => {
          throw new LayoutError(
            'We could not delete the task. Please try once again, or contact support at servicedesk.cgiaromi.fi@cgi.com'
          )
        })
      )
  }

  const duplicate = () => {
    setLoading(true)
    navigate(`/copy/task/${key!}/${saved!.id!}`)
  }

  const cancel = () => {
    setLoading(true)
    navigate(backPath)
  }

  const [assets, setAssets] = useState<AssetData[]>(saved?.assets ? saved.assets : [])

  const [showAlarm, setShowAlarm] = useState(saved && saved.alarmsEnabled !== undefined ? saved.alarmsEnabled : true)

  const removeAsset = (id: string): void => {
    const newAssets = assets ? assets.filter(a => a.id !== id) : []
    setAssets(newAssets)
  }
  useEffect(() => {
    if (valueExists(form.minValue) && valueExists(form.maxValue)) {
      triggerValidation(['minValue', 'maxValue'])
    }
  }, [form.minValue, form.maxValue])
  const addSelectedAsset = useCallback(
    (asset: AssetData) => {
      const addedAsset = asset
      setAssets([...assets, addedAsset])
    },
    [assets]
  )
  useEffect(() => {
    if (!showAlarm) {
      triggerValidation()
    }
  }, [showAlarm])

  const writableIds = state.me?.accessRights.write.sites || []
  const matchingSites = saved?.sites?.filter(s => writableIds?.includes(s.id))
  const siteIds = saved?.sites?.map(s => s.id)
  const editingDisabled = matchingSites?.length !== siteIds?.length

  return {
    editingDisabled,
    setValue,
    setAutomaticReminder,
    setLateByDays,
    form,
    register,
    submit,
    update,
    duplicate,
    remove,
    cancel,
    removeAsset,
    addSelectedAsset,
    scheduledPayload,
    toggleShow,
    handleScheduler,
    reverseSchedule,
    defaultSuggestions,
    setDefaultSuggestions,
    showAlarm,
    setShowAlarm,
    assets,
    errors,
    hasErrors: !formState.isValid,
    formState,
    triggerValidation,
    loading,
    backPath,
    modalOpen,
    automaticReminder,
    lateByDays,
    setModalOpen,
    reset
  }
}

export function useTaskGroupSelect(taskKey: TaskTypeKey, saved?: TaskGroup) {
  const {state} = useAppState()
  const {t} = useTranslation('tasks')
  // gather the options
  const taskType = state.taskTypeById[taskKey]
  const fixedTaskTypeGroups = taskType!.taskTypeGroups
  const fixedTaskGroups: TaskGroup[] = fixedTaskTypeGroups.map(group => {
    return group.taskGroup
  })
  const customTaskGroups = state.customTaskGroups
  const allGroups = [...fixedTaskGroups, ...customTaskGroups]
  const taskGroupOptions = allGroups.map(o => ({option: t(...taskGroupNameArgs(o.name)), id: o.id}))

  // get the default option
  const defaultTaskGroup = fixedTaskTypeGroups.find(tg => {
    return tg.default === true
  })!.taskGroup

  const defaultGroupIndex = taskGroupOptions.findIndex(tg => tg.id === defaultTaskGroup.id)
  const defaultIndex = defaultGroupIndex !== -1 ? defaultGroupIndex : 0
  const [selected, setSelected] = useState(saved ? saved : allGroups[defaultIndex])
  const selectTaskGroup = useCallback(
    (id: string) => {
      setSelected(state.taskGroupById[id])
    },
    [state.taskGroupById]
  )
  return {selectedTaskGroup: selected, selectTaskGroup, taskGroupOptions}
}

export function useMeasurementMethodSelect(defaultOption: string, saved?: MeasurementMethod) {
  const {state} = useAppState()
  const {t} = useTranslation('tasks')
  const measurementOptions = state.initialData.measurementMethods.map(o => ({
    option: t(`tasks:measurementMethods.${o.id}`, o.name),
    id: o.id
  }))
  const defaultMeasurementOptionIndex = measurementOptions.findIndex(tg => tg.id === defaultOption)
  const defaultIndex = defaultMeasurementOptionIndex !== -1 ? defaultMeasurementOptionIndex : 0
  const [selected, setSelected] = useState(saved ? saved : state.initialData.measurementMethods[defaultIndex])
  const selectMethod = useCallback(
    (id: string) => {
      setSelected(state.measurementMethodById[id])
    },
    [state.measurementMethodById]
  )

  return {selectedMeasurementMethod: selected, selectMethod, measurementOptions}
}

// FIXME utils should not proably read data from state
export function useDocumentFolderSelect(defaultOption: string, saved?: DocumentFolder) {
  const {state} = useAppState()
  // FIXME find proper solution for Overmind derived state type not working as expected
  const internalDocumentFolders = (state.v1.documents.internalDocumentFolders as unknown) as DocumentFolder[]
  const documentFolderById: any = state.v1.documents.documentFolderById
  const folderOptions = internalDocumentFolders.map(o => ({option: o.name, id: o.id}))

  const defaultIndex = folderOptions.findIndex(df => df.id === defaultOption) || 0
  const [selected, setSelected] = useState(saved ? saved : internalDocumentFolders[defaultIndex])
  const selectMethod = useCallback(
    (id: string) => {
      setSelected(documentFolderById[id])
    },
    [documentFolderById]
  )

  return {selectedDocumentFolder: selected, selectMethod, folderOptions, setSelected}
}

export function useInstructionsDocumentsFolderSelect(defaultOption: string, saved?: DocumentFolder) {
  const {state} = useAppState()
  // FIXME find proper solution for Overmind derived state type not working as expected
  const documentFolders: DocumentFolder[] = (state.v1.instructions.instructionsFolders as unknown) as DocumentFolder[]
  const documentFolderById: any = state.v1.instructions.documentFolderById
  const folderOptions = documentFolders.map(o => ({option: o.name, id: o.id}))

  const defaultIndex = folderOptions.findIndex(df => df.id === defaultOption) || 0
  const [selected, setSelected] = useState(saved ? saved : documentFolders[defaultIndex])
  const selectMethod = useCallback(
    (id: string) => {
      setSelected(documentFolderById[id])
    },
    [documentFolderById]
  )

  return {selectedDocumentFolder: selected, selectMethod, folderOptions, setSelected}
}

export function useTaskGroupsSelect(defaultSelected?: string[]) {
  const {state} = useAppState()
  const taskGroupOptions = state.initialData.taskGroups.map(o => ({option: o.name, id: o.id}))

  const [selected, setSelected] = useState(
    state.initialData.taskGroups.filter(tg => {
      return includes(defaultSelected ? defaultSelected : taskGroupOptions.map(o => o.id), tg.id)
    })
  )
  const selectMethod = useCallback(
    (ids: string[]) => {
      setSelected(
        state.initialData.taskGroups.filter(tg => {
          return includes(ids, tg.id)
        })
      )
    },
    [state.initialData.taskGroups]
  )

  return {selectedTaskGroups: selected, selectMethod, taskGroupOptions}
}

export interface DeviceTypeObject {
  [key: string]: FixedEquipmentTypes[]
}

export const useDeviceList = (selectedDevices: FixedEquipmentTypes[]) => {
  const DEVICE_TYPES: DeviceTypeObject = {
    diswasher: [FixedEquipmentTypes.Dishwasher],
    storage: [FixedEquipmentTypes.Storage, FixedEquipmentTypes.Freezer],
    cooler: [FixedEquipmentTypes.Cooler]
  }

  const options = Object.keys(DEVICE_TYPES)

  const reduceToCheckBoxObject = (list: string[], selected: boolean) => {
    return list.reduce((a, b) => {
      return {...a, [b]: selected}
    }, {})
  }

  const getSelectedDeviceOptions = (devices: FixedEquipmentTypes[]) => {
    const foundOptions = devices.map(device => options.find(key => DEVICE_TYPES[key].includes(device)))
    const selectedOptions =
      devices.includes(FixedEquipmentTypes.Freezer) || devices.includes(FixedEquipmentTypes.Storage)
        ? foundOptions.concat('storage')
        : foundOptions
    const cleanedSelection = selectedOptions.filter(option => option !== undefined) as string[]
    return cleanedSelection.length > 0
      ? reduceToCheckBoxObject(cleanedSelection, true)
      : reduceToCheckBoxObject(options, false)
  }
  const savedDevices =
    selectedDevices.length > 0 ? getSelectedDeviceOptions(selectedDevices) : reduceToCheckBoxObject(options, false)
  const {register, watch} = useForm<{[key: string]: boolean}>({
    defaultValues: savedDevices
  })

  const formDevices = watch()
  const getSelectedDevicesArray = Object.keys(formDevices)
    .filter(device => formDevices[device])
    .map<FixedEquipmentTypes[]>(device => DEVICE_TYPES[device])
    .flat()
  return {register, getSelectedDevicesArray, DEVICE_TYPES}
}

export function useRestaurantList(saved?: Site[]) {
  const {state} = useAppState()
  const currentSite = state.site

  const savedSites = saved
    ? saved.reduce((a, b) => {
        return {...a, [b.id]: true}
      }, {})
    : {
        [currentSite!.id]: true
      }

  const {register, watch, setValue} = useForm<{[key: string]: boolean}>({
    defaultValues: savedSites
  })

  const ob = watch()
  const list = Object.keys(ob)
    .filter(val => ob[val])
    .map(a => ({id: a}))

  const toggleAll = (all: Site[]) => {
    if (all.length !== list.length) {
      const saved = all.map(a => ({[a.id]: true}))
      setValue(saved)
    } else {
      // empty the list
      setValue(all.map(allSite => ({[allSite.id]: false})))
      // // reset the list information to earlier saved sites state
      // setValue(
      //   all.map(allSites =>
      //     saved !== undefined && !!saved.find(savedSites => savedSites.id === allSites.id)
      //       ? {[allSites.id]: true}
      //       : {[allSites.id]: false}
      //   )
      // )
    }
  }
  return {register, list, toggleAll}
}

export function useRestaurantSelect(saved?: Site) {
  const {state} = useAppState()
  const currentSite = state.site
  const sites = normalizeSites(state.chainsById[state.selectedChainId!])
  const [selected, setSelected] = useState(saved ? saved : currentSite)
  const {register, watch} = useForm({defaultValues: selected ? selected.id : undefined})
  const value = watch('siteChoices')

  const handleChange = useCallback(() => {
    setSelected(
      sites.find(site => {
        return site.id === value
      })
    )
  }, [sites, value, setSelected])

  useEffect(() => {
    handleChange()
  }, [handleChange])

  return {register, sites, selectedSite: selected}
}

export function payloadStr2float(payload: any) {
  if (payload.value && payload.value.length && typeof payload.value === 'string') {
    return {
      ...payload,
      value: parseFloat(payload.value)
    }
  }
  return payload
}
export function normalizeSites(chain: Chain) {
  const locGroups = chain.brands.reduce<LocationGroup[]>((a, b) => [...a, ...b.locationGroups], [])
  const locations = locGroups.reduce<Location[]>((a, b) => [...a, ...b.locations], [])
  return locations.reduce<Site[]>((a, b) => [...a, ...b.sites], [])
}

export function normalizeLocations(chain: Chain) {
  const locGroups = chain.brands.reduce<LocationGroup[]>((a, b) => [...a, ...b.locationGroups], [])
  const locations = locGroups.reduce<Location[]>((a, b) => [...a, ...b.locations], [])
  return locations
}

export function normalizeLocationGroups(chain: Chain) {
  const locGroups = chain.brands.reduce<LocationGroup[]>((a, b) => [...a, ...b.locationGroups], [])
  return locGroups
}

export function normalizeBrands(chain: Chain) {
  const brands = chain.brands
  return brands
}

export function normalizeChains(chain: Chain) {
  return chain
}

export function getOrgLevel(ob: OrgLevel, chain: Chain) {
  switch (ob.type) {
    case OrgLevelType.SITE: {
      return normalizeSites(chain).find(site => site.id === ob.id)
    }
    case OrgLevelType.LOCATION: {
      return normalizeLocations(chain).find(location => location.id === ob.id)
    }
    case OrgLevelType.LOCATION_GROUP: {
      return normalizeLocationGroups(chain).find(group => group.id === ob.id)
    }
    case OrgLevelType.BRAND: {
      return normalizeBrands(chain).find(brand => brand.id === ob.id)
    }
    case OrgLevelType.CHAIN: {
      return normalizeChains(chain)
    }
    default: {
      return {chains: [chain]}
    }
  }
}

/**
 * Helper function to change empty strings (that should be doubles/integers) to undefined
 * @param value
 */

export function handleFormNumber(value?: string | number | null) {
  if (valueExists(value)) {
    if (typeof value === 'number') {
      return value
    } else if (typeof value === 'string') {
      return parseFloat(value)
    }
  } else return undefined
}

export function convertPayloadTemperature(site: Site, value?: string | number) {
  const unit = site.temperatureUnit
  if (value && typeof value === 'string' && !isNaN(parseFloat(value)) && unit === 'F') {
    return farenheit2celcius(parseFloat(value))
  } else {
    console.error('tried to convert non numeric value', value)
    return value
  }
}
export function convertUnit(site: Site, value?: number) {
  const unit = site.temperatureUnit
  if (value === undefined || value === null) return undefined
  if (unit === 'F') {
    return celcius2farenheit(value)
  } else {
    return value
  }
}
export function reverseConvertUnit(site: Site, value?: number | string) {
  const unit = site.temperatureUnit
  if (value === undefined || value === null) return undefined
  if (unit === 'F') {
    value = typeof value === 'number' ? value : parseFloat(value)
    return farenheit2celcius(value)
  } else {
    value = typeof value === 'number' ? value : parseFloat(value)
    return value
  }
}
export function reverseConvertWeight(site: Site, value?: number | string) {
  if (valueExists(value)) {
    if (site.weightUnit === 'LB') {
      return lbs2kg(Number(value))
    } else return Number(value)
  }
  return undefined
}
/**
 * Function to set localized value string back to float
 * @param value
 */
export function deLocalizeValue(locale: string, value?: string | number) {
  const newValue = typeof value === 'string' ? new NumberParser(locale).parse(value) : value
  return newValue
}

function farenheit2celcius(value: number) {
  return (value - 32) / 1.8
}

function celcius2farenheit(value: number) {
  return 1.8 * value + 32
}

export function lbs2kg(value: number) {
  return value * 0.45359237
}
export function kg2lbs(value: number) {
  return value / 0.45359237
}

export const tempUnitStr = (v: string) => `°${v}`
export const shortTempUnitStr = (v: string) => `°`

export function handleTaskTempValues(task: Partial<AllTasks>, site: Site) {
  return {...task, minValue: convertUnit(site, task.minValue), maxValue: convertUnit(site, task.maxValue)}
}

export function handlePayloadTempValues(payload: AnyTaskPayload, site: Site) {
  return {
    ...payload,
    minValue: convertPayloadTemperature(site, payload.minValue),
    maxValue: convertPayloadTemperature(site, payload.maxValue)
  }
}

export function handleTempEventValues(payload: PossibleEvent, site: Site) {
  return {
    ...payload,
    value: convertPayloadTemperature(site, payload.value),
    coolingValues: payload.coolingValues
      ? payload.coolingValues.map(cv => convertPayloadTemperature(site, cv.value))
      : undefined
  }
}

export function handleWeightValue(site: Site, value?: number | string | null) {
  if (typeof value === 'number') {
    if (site.weightUnit !== 'KG') {
      return kg2lbs(value)
    }
    return value
  }
  return undefined
}

export function getWeightUnitStr(value: string) {
  if (value === 'KG') {
    return i18n.t('common:weightUnits.short.kilogram', 'kg')
  } else if (value === 'LB') return i18n.t('common:weightUnits.short.pound', 'lbs')
  else return ''
}

export function round(value: string | number, precision = 1) {
  if (typeof value === 'string') {
    return parseFloat(parseFloat(value).toFixed(precision))
  }
  return parseFloat(value.toFixed(precision))
}

export function handleUnit(site: Site, taskType: TaskTypeKey) {
  if (
    taskType === 'cooling' ||
    taskType === 'automaticCooling' ||
    taskType === 'temperature' ||
    taskType === 'equipmentTemperature'
  ) {
    return `°${site.temperatureUnit}`
  } else return undefined
}

export function handleAnyUnit(site: Site, task: CompletedTask) {
  const taskType = task.taskType.id
  if (
    taskType === 'cooling' ||
    taskType === 'automaticCooling' ||
    taskType === 'temperature' ||
    taskType === 'equipmentTemperature'
  ) {
    return `°${site.temperatureUnit}`
  } else if (taskType === 'waste' && task.wasteTaskType) {
    return getWasteUnit(site, task.wasteTaskType as any)
  } else return undefined
}

export const localTempValueRaw = (value: number, site: Site) => {
  if (site.temperatureUnit === 'F') {
    return celcius2farenheit(value)
  } else return value
}
export const localTempValue = (value: number, site: Site) => {
  if (site.temperatureUnit === 'F') {
    return round(celcius2farenheit(value))
  } else return round(value)
}

export const localValue = (value: number, taskType: TaskTypeKey) => {
  if (taskType === 'temperature' || taskType === 'equipmentTemperature') {
    return value
  }
}

export const isFloat = (value: string) => RegExp(/^[-0-9,.]*$/g).test(value)
export const handleFloatInput = (value: string) =>
  (isFloat(value) ? value : value.slice(0, value.length - 1)).toString()

export function getSites(chain: Chain) {
  return chain.brands.reduce<Site[]>((a, b) => {
    return [
      ...a,
      ...b.locationGroups.reduce<Site[]>((a, b) => {
        return [
          ...a,
          ...b.locations.reduce<Site[]>((a, b) => {
            return [...a, ...b.sites.map(s => s)]
          }, [])
        ]
      }, [])
    ]
  }, [])
}

// https://stackoverflow.com/questions/4907843/open-a-url-in-a-new-tab-and-not-a-new-window
export function openInNewTab(href: string) {
  Object.assign(document.createElement('a'), {
    target: '_blank',
    href
  }).click()
}

export function generateTaskListHeader(task: TaskListItemData | AnyTaskData) {
  switch (task.taskType.id) {
    case 'equipmentTemperature':
      return i18n.t(...taskGroupNameArgs(task.taskGroup.name)) + (task.equipmentName ? ` - ${task.equipmentName}` : '')
    case 'waste':
      return i18n.t(...taskGroupNameArgs(task.taskGroup.name)) + (task.target ? ` - ${task.target}` : '')
    case 'hygiene':
      return i18n.t(...taskGroupNameArgs(task.taskGroup.name)) + (task.target ? ` - ${task.target}` : '')
    default:
      return i18n.t(...taskGroupNameArgs(task.taskGroup.name))
  }
}

export function translateTaskGroupName(name: string) {
  return i18n.t(taskGroupNameArgs(name))
}

export const translateTaskType = (taskTypeId: string) => {
  switch (taskTypeId) {
    case 'cooling':
    case 'automaticCooling':
      return i18n.t('tasks:labels.taskType.cooling', 'Cooling')
    case 'equipmentTemperature':
      return i18n.t('tasks:labels.taskType.equipmentTemperature', 'Equipment temperature')
    case 'temperature':
      return i18n.t('tasks:labels.taskType.temperature', 'Temperature')
    case 'waste':
      return i18n.t('tasks:labels.taskType.waste', 'Waste')
    case 'hygiene':
      return i18n.t('tasks:labels.taskType.hygiene', 'Surface hygiene')
    case 'singlechoice':
      return i18n.t('tasks:labels.taskType.singlechoice', 'Single choice')
    case 'todo':
      return i18n.t('tasks:labels.taskType.todo', 'Todo')
    case 'manual':
      return i18n.t('tasks:labels.taskType.manual', 'Manual')
    default:
      return '-'
  }
}

export function useFileUpload({cb, savedAsset}: {savedAsset?: AssetData; cb?: (a?: AssetData) => void}) {
  const fileRef = useRef<HTMLInputElement>(null)

  const {actions} = useAppState()
  const [loading, setLoading] = useState(false)
  const [asset, setAsset] = useState<AssetData | null>(savedAsset || null)
  async function startUpload(file: File) {
    if (cb) {
      cb(undefined)
    }
    setLoading(true)
    const data = await actions.createUploadUrl({
      fileName: file.name || 'newImage.jpg',
      fileType: file.type || 'image/jpeg'
    })
    const xhr = new XMLHttpRequest()
    xhr.open('PUT', data.uploadURL)
    xhr.onreadystatechange = async function() {
      if (xhr.readyState === 4) {
        if (xhr.status === 200) {
          const asset = {
            name: file.name || 'newImage.jpg',
            path: data.filePath,
            mimeType: file.type || 'image/jpeg',
            size: file.size
          }
          const newAsset = {
            name: asset.name,
            assetPath: `${asset.path}`,
            assetMimeType: asset.mimeType,
            assetSize: asset.size,
            assetType: asset.mimeType.startsWith('image')
              ? AssetType.PHOTO
              : asset.mimeType.startsWith('video')
              ? AssetType.VIDEO
              : AssetType.DOCUMENT
          }
          console.debug('useFileUpload creating asset', {newAsset})
          const assetData = await actions.createAsset(newAsset)
          setAsset(assetData)
          if (cb) {
            cb(assetData)
          }
        } else {
          // TODO handle upload errors in ui
          console.error('Error while sending the image to S3')
        }
      }
    }
    xhr.onloadend = function() {
      setLoading(false)
    }
    xhr.setRequestHeader('Content-Type', file.type || 'image/jpeg')
    xhr.send(file)
  }
  const handleUploadClick = (e: React.ChangeEvent<HTMLInputElement>) => {
    e.preventDefault()
    if (e.target && e.target.files && !!e.target.files.length) {
      const file = e.target.files[0]
      console.debug('handleUploadClick', {file})
      startUpload(file)
    }
  }
  return {loading, asset, fileRef, handleUploadClick}
}

export class ApiVersionError extends Error {
  originalError?: ky.HTTPError
  constructor(msg: string, originalError?: ky.HTTPError) {
    super()
    this.message = msg
    this.originalError = originalError
  }
}
export class LayoutError extends Error {
  originalError?: ky.HTTPError
  constructor(msg: string, originalError?: ky.HTTPError) {
    super()
    this.message = msg
    this.originalError = originalError
  }
}
/**
 * Formats numbers to localized strings, used in inputs and number displays
 * @param locale
 * @param value
 */
export function localNumberFormat(locale: string, value?: number | string | null) {
  if (valueExists(value)) {
    value = typeof value === 'number' ? round(value) : new NumberParser(locale).parse(value!.replace(' ', '').trim())

    // Whoa, apparently toLocaleString changes minus sign unicode, causing parseFloat to produce NaN, thus the replace
    return value.toLocaleString(locale).replace(/[\u2212]/, '-')
  } else {
    return undefined
  }
}

export function nuke() {
  localStorage.clear()
  window.location.reload()
}

/**
 *
 * @param effect a function returning your promise. This prevents promise to be executed until you want it to!
 * @param triggerOnLoad if the promise should resolve immediately or on trigger
 */
export function useData<R>(effect: () => Promise<R>, triggerOnLoad: boolean = true) {
  const [data, setData] = useState<R | null>(null)
  const [error, setError] = useState(false)
  const [loading, setLoading] = useState(true)
  const effectRef = useRef(effect)
  const trigger = useCallback(() => {
    effectRef
      .current()
      .then(data => {
        console.debug('got data', data)
        setLoading(false)
        setData(data)
      })
      .catch(e => {
        console.debug('got error', e)
        setError(false)
        setLoading(false)
      })
  }, [effectRef])
  useEffect(() => {
    if (triggerOnLoad) {
      trigger()
    }
  }, [trigger, triggerOnLoad])
  return {data, error, loading, trigger}
}

export function isInRange(value?: number, min?: number, max?: number) {
  if (valueExists(value)) {
    if (valueExists(min) && valueExists(max)) {
      return value! >= min! && value! <= max!
    } else if (valueExists(min) && !valueExists(max)) {
      return value! >= min!
    } else if (!valueExists(min) && valueExists(max)) {
      return value! <= max!
    }
  }
  return false
}

export function shortTgName(tg: string) {
  return `tasks:taskGroups.${tg.replace(' ', '').toLowerCase()}`
}

// Hook
// export function useWindowSize() {
//   const isClient = typeof window === 'object'
//   const [windowSize, setWindowSize] = useState(getSize)

//   function getSize() {
//     return {
//       width: isClient ? window.innerWidth : undefined,
//       height: isClient ? window.innerHeight : undefined
//     }
//   }
//   useEffect(() => {
//     if (!isClient) {
//       return
//     }
//     function handleResize() {
//       setWindowSize(getSize())
//     }
//     window.addEventListener('resize', handleResize)
//     return () => {
//       window.removeEventListener('resize', handleResize)
//     }
//   }, []) // Empty array ensures that effect is only run on mount and unmount
//   return windowSize
// }

/**
 * Helper function to concatenate task group names. Used to translate fixed names in t function:
 * e.g. t(...taskGroupNameArgs('Audit')).
 * Remember to use "tasks" namespace: const {t} = useTranslation('tasks')!
 * @param name Task group name
 */
export function taskGroupNameArgs(name: string): [string, string] {
  return [`tasks:taskGroups.${name.replace(' ', '').toLowerCase()}`, name]
}

// export function wasteTypesArgs(name: string): [string, string] {
//   return [`appliances:wasteTypes.${name.replace(' ', '').toLowerCase()}`, name]
// }

export const assertUnreachable = (never: never) => {}
