import {AsyncAction} from 'overmind'
import {resolveAlarmIfFound} from '../alarms/utils'
import {Appliance, MeasurementType, Sensor} from './state'
import _ from 'lodash'
import {isEmptyValue} from '../../state/performTask/effects'

const PAGE_OFFSET = 0
const PAGE_LIMIT = 25
const SENSOR_RECORD_MAX_COUNT = 500

export const getAppliancesForSite: AsyncAction<{siteId: string}, void> = async ({state, effects}, params) => {
  try {
    state.v1.appliances.appliances.loading = true
    state.v1.appliances.appliances.error = undefined
    const appliances = await effects.v1.appliances.appliancesApi.getAppliancesForSite(params.siteId)
    state.v1.appliances.appliances.data.appliances = appliances
  } catch (err) {
    state.v1.appliances.appliances.error = err as Error
  } finally {
    state.v1.appliances.appliances.loading = false
  }
}

export const resetAppliances: AsyncAction<void, void> = async ({state}) => {
  state.v1.appliances.appliances.data.appliances = undefined
}

export const getMetadataForSensor: AsyncAction<{sensorId: string; siteId: string}, void> = async (
  {state, effects},
  params
) => {
  try {
    state.v1.appliances.sensorMetadata.loading = true
    state.v1.appliances.sensorMetadata.error = undefined
    const sensorMetadata = await effects.v1.appliances.appliancesApi.getMetadataForSensor(
      params.sensorId,
      params.siteId
    )
    state.v1.appliances.sensorMetadata.data.sensorMetadata = sensorMetadata
    state.v1.appliances.sensorMetadata.data.sensorId = params.sensorId
  } catch (err) {
    state.v1.appliances.sensorMetadata.error = err as Error
  } finally {
    state.v1.appliances.sensorMetadata.loading = false
  }
}

export const getAlarmsForSensor: AsyncAction<
  {applianceId: string; sensorId: string; sensorRole: string; siteId: string},
  void
> = async ({state, effects}, params) => {
  try {
    state.v1.appliances.sensorAlarms.loading = true
    state.v1.appliances.sensorAlarms.error = undefined
    const alarms = await effects.v1.appliances.appliancesApi.getAlarmsForSensor(
      params.applianceId,
      params.sensorRole,
      params.siteId
    )
    state.v1.appliances.sensorAlarms.data.sensorAlarms = alarms
    if (!state.v1.appliances.appliances.data.appliances) {
      return
    }
    const sensor = findSensor(
      state.v1.appliances.appliances.data.appliances,
      ['id', 'role'],
      [params.sensorId, params.sensorRole]
    )
    if (!sensor) {
      return
    }
    state.v1.appliances.sensorAlarms.data.sensorId = sensor?.id
  } catch (err) {
    state.v1.appliances.sensorAlarms.error = err as Error
  } finally {
    state.v1.appliances.sensorAlarms.loading = false
  }
}

export const getStatusForAppliance: AsyncAction<{applianceId: string}, void> = async ({state, effects}, params) => {
  try {
    state.v1.appliances.applianceStatuses.loading = true
    state.v1.appliances.applianceStatuses.error = undefined
    const applianceStatuses = await effects.v1.appliances.appliancesApi.getStatusForAppliance(params.applianceId)
    state.v1.appliances.applianceStatuses.data.applianceStatuses = applianceStatuses
    state.v1.appliances.applianceStatuses.data.applianceId = params.applianceId
  } catch (err) {
    state.v1.appliances.applianceStatuses.error = err as Error
  } finally {
    state.v1.appliances.applianceStatuses.loading = false
  }
}

export const putStatusForAppliance: AsyncAction<
  {applianceId: string; suspended: boolean; updatedBy?: string; reason?: string},
  void
> = async ({state, effects}, params) => {
  try {
    state.v1.appliances.applianceStatuses.loading = true
    state.v1.appliances.applianceStatuses.error = undefined
    const statuses = await effects.v1.appliances.appliancesApi.putStatusForAppliance(
      params.applianceId,
      params.suspended,
      params.updatedBy,
      params.reason
    )
    const appliance = state.v1.appliances.appliances.data.appliances?.find(a => a.id === params.applianceId)
    if (appliance) {
      appliance.suspended = params.suspended
    }
    if (state.v1.appliances.applianceStatuses.data.applianceId === params.applianceId) {
      state.v1.appliances.applianceStatuses.data.applianceStatuses = statuses
    }
  } catch (err) {
    state.v1.appliances.applianceStatuses.error = err as Error
  } finally {
    state.v1.appliances.applianceStatuses.loading = false
  }
}

export const resolveAlarm: AsyncAction<
  {alarmId: string; resolution: string; resolvedBy: string; siteId: string},
  void
> = async ({state, effects}, params) => {
  try {
    state.v1.appliances.sensorAlarms.loading = true
    state.v1.appliances.sensorAlarms.error = undefined
    let payload = {}
    payload = _.omitBy({resolution: params.resolution, resolvedBy: params.resolvedBy}, isEmptyValue)
    const resolvedAlarm = await effects.v1.alarms.alarmsApi.updateAlarm(params.alarmId, params.siteId, payload)
    if (state.v1.appliances.sensorAlarms.data.sensorAlarms) {
      resolveAlarmIfFound(state.v1.appliances.sensorAlarms.data.sensorAlarms, params.alarmId, resolvedAlarm)
    }
  } catch (err) {
    state.v1.appliances.sensorAlarms.error = err as Error
  } finally {
    state.v1.appliances.sensorAlarms.loading = false
  }
}

export const getCoolingCyclesForAppliance: AsyncAction<
  {applianceId: string; offset?: number; limit?: number; reset?: boolean},
  void
> = async ({state, effects}, params) => {
  try {
    state.v1.appliances.coolingCycles.loading = true
    state.v1.appliances.coolingCycles.error = undefined
    const offset = typeof params.offset === 'number' ? params.offset : PAGE_OFFSET
    const limit = typeof params.limit === 'number' ? params.limit : PAGE_LIMIT
    const response = await effects.v1.appliances.appliancesApi.getCoolingCyclesForAppliance(
      params.applianceId,
      offset,
      limit
    )
    state.v1.appliances.coolingCycles.data.coolingCycles =
      state.v1.appliances.coolingCycles.data.applianceId !== params.applianceId || params.reset
        ? response.data
        : [...(state.v1.appliances.coolingCycles.data.coolingCycles ?? []), ...response.data]
    state.v1.appliances.coolingCycles.data.count = response.count
    state.v1.appliances.coolingCycles.data.applianceId = params.applianceId
  } catch (err) {
    state.v1.appliances.coolingCycles.error = err as Error
  } finally {
    state.v1.appliances.coolingCycles.loading = false
  }
}

export const getSensorRecordsForAppliance: AsyncAction<
  {
    applianceId: string
    siteId: string
    from: string
    to: string
    measurementType: MeasurementType
    sensorRole?: string
    maxCount?: number
  },
  void
> = async ({state, effects}, params) => {
  const sensorRecords = state.v1.appliances.sensorRecords[params.measurementType]
  try {
    sensorRecords.loading = true
    sensorRecords.error = undefined
    const data = await effects.v1.appliances.appliancesApi.getSensorRecordsForAppliance(
      params.applianceId,
      params.siteId,
      params.from,
      params.to,
      params.measurementType,
      params.sensorRole,
      params.maxCount ?? SENSOR_RECORD_MAX_COUNT
    )
    sensorRecords.data = data
    const appliance = state.v1.appliances.appliances.data.appliances?.find(a => a.id === params.applianceId)
    if (!appliance) {
      return
    }
    const sensor =
      appliance.sensors?.length === 1
        ? appliance.sensors[0]
        : appliance.sensors?.find(s => s.role === params.sensorRole)
    if (!sensor) {
      return
    }
    sensorRecords.data.sensorId = sensor?.id
  } catch (err) {
    sensorRecords.error = err as Error
    throw err
  } finally {
    sensorRecords.loading = false
  }
}

const findSensor = (appliances: Appliance[], keys: (keyof Sensor)[], values: string[]): Sensor | undefined => {
  let sensor: Sensor | undefined = undefined
  appliances.every(appliance => {
    appliance.sensors?.every(s => {
      const isMatch = keys.reduce((res, key, i) => {
        return res && s[key] === values[i]
      }, true)
      if (isMatch) {
        sensor = s
        return false
      }
      return true
    })
    if (sensor) {
      return false
    }
    return true
  })
  return sensor
}
