import React from 'react'
import {useTranslation} from 'react-i18next'
import {Baseline, Grid, Row, InvisibleContainer, GridSpan} from '../../Layout/Grid'
import {LabelInput, Select, Toggler, BigLabelInput} from '../../Atoms/Forms'
import {Button, ButtonRowWrap, ButtonRowGrid} from '../../Atoms/Buttons'
import {
  useTaskForm,
  handleFormNumber,
  useTaskGroupSelect,
  useMeasurementMethodSelect,
  useRestaurantList,
  reverseConvertUnit,
  tempUnitStr,
  handleTaskTempValues,
  valueExists
} from '../../../config/utils'
import {TemperatureTaskResponse} from '../../../state/rest'
import Scheduler from '../../Organisms/Scheduler/Scheduler'
import {MainLayoutWithoutStretch, RightSidebar, MainContent} from '../../Layout/Layout'
import LocationsList from '../../Organisms/Locations/LocationsList'
import {useAppState} from '../../../state'
import {SmallUnit, P} from '../../Atoms/Typography'
import {HeaderRow, HeaderActions} from '../../Molecules/ViewComponents/ViewHeader'
import TaskMenuModal from './TaskMenuModal'
import {DefaultTagsWidget} from '../../Organisms/Tags/DefaultSuggestionWidget'
import {CommonInformationFields, TasksGoBackHeaderTitle} from './CommonTasksComponents'

interface FormProps {
  savedData?: TemperatureTaskResponse
}
const TASK_KEY = 'temperature'

const CreateTemperatureTask = ({savedData}: FormProps) => {
  const {t} = useTranslation(['tasks'])
  const {state} = useAppState()

  const isEditing = !!savedData && !!savedData.id
  const isDuplicate = !!savedData && !savedData.id

  const saved = savedData
    ? handleTaskTempValues(
        isDuplicate && savedData.name
          ? {
              ...savedData,
              name: `${t('tasks:labels.copyOf', 'Copy of')} ${savedData.name.toLowerCase()}`,
              sites: [state.site!]
            }
          : savedData,
        state.site!
      )
    : undefined

  // task form controller hook
  const fc = useTaskForm(TASK_KEY, saved)
  const rc = useRestaurantList(isDuplicate ? undefined : saved ? saved.sites : undefined)
  const {taskGroupOptions, selectTaskGroup, selectedTaskGroup} = useTaskGroupSelect(
    TASK_KEY,
    saved ? saved.taskGroup : undefined
  )

  const {selectMethod, selectedMeasurementMethod, measurementOptions} = useMeasurementMethodSelect(
    'device',
    saved ? saved.measurementMethod : undefined
  )

  const {minValue, maxValue} = fc.form
  const taskPayload = {
    minValue: reverseConvertUnit(state.site!, handleFormNumber(minValue)),
    maxValue: reverseConvertUnit(state.site!, handleFormNumber(maxValue)),
    defaultSuggestions: fc.defaultSuggestions,
    taskGroup: selectedTaskGroup,
    measurementMethod: selectedMeasurementMethod,
    unit: state.site!.temperatureUnit,
    sites: rc.list,
    assets: fc.assets,
    lateByDays: fc.lateByDays
  }

  const isFormValid = () => {
    const isNameValid = !!fc.form.name
    const minValue = fc.form.minValue
    const maxValue = fc.form.maxValue
    const isMinValueValid =
      valueExists(minValue) &&
      valueExists(maxValue) &&
      parseFloat(minValue!.toString()) < parseFloat(maxValue!.toString())
    return isNameValid && isMinValueValid
  }

  return (
    <MainLayoutWithoutStretch>
      <MainContent variant="white">
        <HeaderRow
          modal={
            isEditing && (
              <TaskMenuModal
                isOpen={fc.modalOpen}
                onClose={() => fc.setModalOpen(false)}
                onDelete={fc.editingDisabled ? undefined : fc.remove}
                onDuplicate={fc.duplicate}
              />
            )
          }
        >
          <TasksGoBackHeaderTitle
            label={
              isEditing
                ? t('tasks:labels.editTask.temperature', 'Edit temperature task')
                : t('tasks:labels.newTask.temperature', 'Add temperature task')
            }
            isEditing={isEditing}
          />
          {isEditing && <HeaderActions onMenu={() => fc.setModalOpen(true)} />}
        </HeaderRow>
        <InvisibleContainer>
          <Baseline>
            <Grid>
              <BigLabelInput
                required
                placeholder={t('tasks:placeholders.newTaskName', 'New task name')}
                name="name"
                id="name"
                labelText={t('tasks:labels.taskName', 'Task name')}
                ref={fc.register({required: true})}
                errorMsg={!!fc.errors.name ? t('common:validation.requiredField', 'Required field') : undefined}
              ></BigLabelInput>
              <div className="empty"></div>
            </Grid>
            <Grid>
              <Baseline>
                <Select
                  returnId
                  nativeProps={{
                    name: 'taskGroup',
                    onChange(e) {
                      selectTaskGroup(e.target.value)
                    },
                    value: selectedTaskGroup.id
                  }}
                  id="taskGroup"
                  label={t('tasks:labels.taskGroup', 'Task group')}
                  options={taskGroupOptions}
                />
                <Select
                  returnId
                  nativeProps={{
                    name: 'measurementMethod',
                    onChange(e) {
                      selectMethod(measurementOptions.find(mo => mo.id === e.target.value)!.id)
                    },
                    value: selectedMeasurementMethod.id
                  }}
                  id="measurementMethod"
                  label={t('tasks:labels.measurementMethod', 'Measurement method')}
                  options={measurementOptions}
                />
                <Baseline>
                  <Toggler
                    label={t('tasks:labels.alarms', 'Alarms')}
                    values={[t('common:togglerValues.off', 'Off'), t('common:togglerValues.on', 'On')]}
                    initiallyChecked={fc.showAlarm}
                    name="isAlarms"
                    onClick={async () => {
                      fc.setShowAlarm(!fc.showAlarm)
                      fc.reset()
                      fc.triggerValidation()
                    }}
                  />

                  {fc.showAlarm && (
                    <Row childMargin="1rem">
                      <LabelInput
                        required
                        type="number"
                        ref={r =>
                          fc.register({
                            required:
                              fc.showAlarm &&
                              (t('common:validation.requiredField', 'This field is required') as string),
                            validate: value =>
                              (valueExists(maxValue) ? parseFloat(value) < maxValue! : true) ||
                              (t('common:validation.valueTooHigh', 'Value must be lower than max value') as string)
                          })(r)
                        }
                        errorMsg={fc.errors.minValue?.message}
                        name="minValue"
                        labelText={t('common:general.alarmMinLimit', 'Alarm min limit')}
                      />
                      <LabelInput
                        required
                        type="number"
                        ref={r =>
                          fc.register({
                            required:
                              fc.showAlarm &&
                              (t('common:validation.requiredField', 'This field is required') as string),
                            validate: value =>
                              (valueExists(minValue) ? parseFloat(value) > minValue! : true) ||
                              (t('common:validation.valueTooLow', 'Value must be higher than min value') as string)
                          })(r)
                        }
                        errorMsg={fc.errors.maxValue?.message}
                        name="maxValue"
                        labelText={t('common:general.alarmMaxLimit', 'Alarm max limit')}
                      />
                      <SmallUnit>{tempUnitStr(state.site!.temperatureUnit)}</SmallUnit>
                    </Row>
                  )}

                  <Toggler
                    label={t('tasks:labels.scheduledTask', 'Scheduled task')}
                    values={[t('common:togglerValues.no', 'No'), t('common:togglerValues.yes', 'Yes')]}
                    initiallyChecked={!!saved && !!saved.schedule}
                    name="isScheduledTask"
                    onClick={() => {
                      fc.toggleShow()
                    }}
                  />
                  {!!fc.scheduledPayload ? (
                    <Scheduler
                      onChange={fc.handleScheduler}
                      schedule={saved ? fc.reverseSchedule(saved.schedule) : undefined}
                      lateByDays={saved ? fc.lateByDays : undefined}
                      onLateByDaysChange={fc.setLateByDays}
                    />
                  ) : null}
                </Baseline>
              </Baseline>
              <Baseline>
                <CommonInformationFields useForm={fc} />
                <DefaultTagsWidget
                  onChange={items => fc.setDefaultSuggestions(items)}
                  savedItems={saved ? saved.defaultSuggestions : undefined}
                  title={t(
                    'tasks:labels.defaultSuggestionTitle',
                    'Define default suggestions for what user is measuring. These suggestions are shown when completing the task.'
                  )}
                />
              </Baseline>
            </Grid>

            <ButtonRowWrap>
              <div className="empty"></div>
              <ButtonRowGrid>
                <Button name="cancel-button" negative variant="secondary" disabled={fc.loading} onClick={fc.cancel}>
                  {t('common:buttons.cancel', 'Cancel')}
                </Button>
                <Button
                  variant="secondary"
                  disabled={!isFormValid() || fc.loading || rc.list.length === 0 || fc.editingDisabled}
                  onClick={() => (isEditing ? fc.update(taskPayload) : fc.submit(taskPayload))}
                >
                  {t('common:buttons.save', 'Save')}
                </Button>
                {fc.editingDisabled && (
                  <GridSpan span={2}>
                    <P>
                      {t(
                        'tasks:messages.taskInUseMessage',
                        'This task is in use for locations you don’t have access to. You can not edit or delete it but you can duplicate the task to create a new one'
                      )}
                    </P>
                  </GridSpan>
                )}
              </ButtonRowGrid>
            </ButtonRowWrap>
          </Baseline>
        </InvisibleContainer>
      </MainContent>
      <RightSidebar>
        <LocationsList
          controller={rc}
          cyKey={'task'}
          label={t('tasks:labels.selectLocation', 'Select which locations use this task')}
        />
      </RightSidebar>
    </MainLayoutWithoutStretch>
  )
}

export default CreateTemperatureTask
