import React, {useState} from 'react'
import {Input, Label} from '../../../Components/Atoms/Forms'
import {useTranslation} from 'react-i18next'

interface LateByDaysSelectorProps {
  lateByDays: number | undefined
  onLateByDaysChange: ((lateByDays: number) => void) | undefined
}

const LateByDaysSelector = ({lateByDays = 1, onLateByDaysChange}: LateByDaysSelectorProps) => {
  const {t} = useTranslation('tasks')
  const [internalValue, setInternalValue] = useState<string | number>(lateByDays)

  const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const value = e.target.value

    if (value === '') {
      setInternalValue('')
      return
    }

    const numValue = Math.max(1, Math.min(Number(value), 31))

    if (numValue !== internalValue) {
      setInternalValue(numValue)
      onLateByDaysChange?.(numValue)
    }
  }

  const handleBlur = () => {
    if (internalValue === '' || Number(internalValue) === 0) {
      setInternalValue(1)
      onLateByDaysChange?.(1)
    }
  }

  return (
    <div>
      <Label>{t('tasks:labels.lateByDays', 'Late By Days (Maximum 31 days)')}</Label>
      <Input
        value={internalValue}
        onChange={handleChange}
        onBlur={handleBlur}
        type="number"
        min={1}
        max={31}
        id="latebydays"
      />
    </div>
  )
}

export default LateByDaysSelector
