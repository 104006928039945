import React, {useEffect, FormEvent} from 'react'
import {Title} from '../../Atoms/Typography'
import {LabelInput} from '../../Atoms/Forms'
import {useForm} from 'react-hook-form'
import {InvisibleContainer, Row, Baseline} from '../../Layout/Grid'
import {FormSubmitButton, StyledLink} from '../../Atoms/Buttons'
import {useAppState} from '../../../state'
import {colors} from '../../../sharedComponents/colors'
import {LoginLogo, TitleWrapper} from './LoginComponents'
import {useTranslation} from 'react-i18next'
import {useNavigation} from 'react-navi'
import {Helmet} from 'react-navi-helmet-async'

interface Form {
  userName: string
  password: string
}
interface Creds {
  password?: string
  email?: string
}

const Login = (creds: Creds) => {
  const fc = useForm<Form>({mode: 'onChange'})
  const {state, actions} = useAppState()
  const {t} = useTranslation('auth')
  const navi = useNavigation()

  const {userName, password} = fc.watch()

  const handleLogin = () => {
    actions.login({email: userName.toLowerCase(), password})
  }
  const handleSubmit = (e: FormEvent) => {
    e.preventDefault()
    handleLogin()
  }

  // use case: mobile app login transparently, using the component parameters (read from query string in router)
  useEffect(() => {
    if (!!creds.email && !!creds.password) {
      actions.login({email: creds.email, password: creds.password})
    }
  }, [creds.email, creds.password, actions])

  return (
    <InvisibleContainer>
      <Helmet title={t('common:routes.loginSignup', 'Login / signup')} />
      <LoginLogo />
      <TitleWrapper>
        <Title level={2} color={colors.system.white}>
          {t('common:tagLine', 'Because you have better things to do')}
        </Title>
      </TitleWrapper>
      <form onSubmit={handleSubmit}>
        <Baseline>
          <LabelInput
            negative={true}
            autoComplete="username"
            type="email"
            labelText={t('auth:labels.emailAddress', 'Email address')}
            name="userName"
            id="userName"
            ref={fc.register({
              required: `${t('common:validation.emailRequired', 'Email address is mandatory')}`,
              pattern: {
                value: /^[^\s@]+@[^\s@]+\.[^\s@]+$/i,
                message: `${t('common:validation.emailInvalid', 'Invalid email address')}`
              }
            })}
            errorMsg={!!fc.errors.userName ? fc.errors.userName!.message : undefined}
          />
          <LabelInput
            negative={true}
            labelText={t('auth:labels.password', 'Password')}
            name="password"
            id="password"
            type="password"
            ref={fc.register({required: true})}
            errorMsg={
              state.authenticationError
                ? t(`auth:authenticationError.${state.authenticationError}`, state.authenticationError)
                : undefined
            }
          />
          <Row>
            <StyledLink
              negative="negative"
              onClick={() => {
                navi.navigate('/login/forgot')
              }}
            >
              {t('auth:labels.forgotPassword', 'Forgot password?')}
            </StyledLink>
          </Row>
          <FormSubmitButton
            type="submit"
            disabled={state.isAuthenticating || !fc.formState.isValid}
            onClick={handleLogin}
          >
            {state.isAuthenticating
              ? t('auth:labels.authenticating', 'Authenticating')
              : t('auth:labels.login', 'Log in')}
          </FormSubmitButton>
        </Baseline>
      </form>
    </InvisibleContainer>
  )
}

export default Login
