import React, {useState} from 'react'
import {useTranslation} from 'react-i18next'
import {Baseline, Grid, Row, InvisibleContainer, GridSpan} from '../../Layout/Grid'
import {LabelInput, Select, Toggler, BigLabelInput} from '../../Atoms/Forms'
import {DefaultTagsWidget} from '../../Organisms/Tags/DefaultSuggestionWidget'
import {Button, ButtonRowGrid, ButtonRowWrap} from '../../Atoms/Buttons'
import {handleFormNumber, useTaskForm, useTaskGroupSelect, useRestaurantList} from '../../../config/utils'
import {WasteTaskResponse} from '../../../state/rest'
import Scheduler from '../../Organisms/Scheduler/Scheduler'
import {MainLayoutWithoutStretch, RightSidebar, MainContent} from '../../Layout/Layout'
import LocationsList from '../../Organisms/Locations/LocationsList'
import {useAppState} from '../../../state'
import {SmallUnit, P} from '../../Atoms/Typography'
import {HeaderRow, HeaderActions} from '../../Molecules/ViewComponents/ViewHeader'
import TaskMenuModal from './TaskMenuModal'
import {Site} from '../../../state/state'
import i from 'i18next'
import {CommonInformationFields, TasksGoBackHeaderTitle} from './CommonTasksComponents'

interface FormProps {
  savedData?: WasteTaskResponse
}
const TASK_KEY = 'waste'

export enum WasteTaskType {
  WasteAmount = 'WasteAmount',
  CustomerAmount = 'CustomerAmount',
  ProductionAmount = 'ProductionAmount'
}

export function getWeightUnitStr(value: string) {
  if (value === 'KG') {
    return 'kg'
  } else if (value === 'LB') return 'lbs'
  else return ''
}

export function getWasteUnit(site: Site, wasteType: WasteTaskType) {
  switch (wasteType) {
    case 'WasteAmount':
      return getWeightUnitStr(site.weightUnit)
    case 'CustomerAmount':
      return i.isInitialized ? i.t('general.customersAmount', 'Customers') : ''
    case 'ProductionAmount':
      return getWeightUnitStr(site.weightUnit)
    default:
      return getWeightUnitStr(site.weightUnit)
  }
}

const CreateWasteTask = ({savedData}: FormProps) => {
  const {t} = useTranslation('tasks')
  const {state} = useAppState()

  const isEditing = !!savedData && !!savedData.id
  const isDuplicate = !!savedData && !savedData.id
  const isNew = !isEditing && !isDuplicate

  const saved = savedData
    ? isDuplicate && savedData.name
      ? {
          ...savedData,
          name: `${t('tasks:labels.copyOf', 'Copy of')} ${savedData.name.toLowerCase()}`,
          sites: [state.site!]
        }
      : savedData
    : undefined

  // task form controller hook
  const fc = useTaskForm(TASK_KEY, saved || {unit: state.site!.weightUnit.toLowerCase()}, isNew)
  const rc = useRestaurantList(isDuplicate ? undefined : saved ? saved.sites : undefined)

  const {taskGroupOptions, selectTaskGroup, selectedTaskGroup} = useTaskGroupSelect(
    TASK_KEY,
    saved ? saved.taskGroup : undefined
  )
  const [ingredientSuggestions, setIngredientSuggestions] = useState(saved ? saved.ingredientSuggestions : [])

  const {maxValue, target} = fc.form
  const taskPayload = {
    maxValue: handleFormNumber(maxValue),
    defaultSuggestions: fc.defaultSuggestions,
    taskGroup: selectedTaskGroup,
    ingredientSuggestions,
    schedule: fc.scheduledPayload,
    target,
    sites: rc.list,
    assets: fc.assets,
    wasteTaskType: fc.form.wasteTaskType,
    automaticReminder: fc.automaticReminder,
    lateByDays: fc.lateByDays
  }
  const unit = fc.form.wasteTaskType ? getWasteUnit(state.site!, fc.form.wasteTaskType) : undefined

  const infoBlocks = () => (
    <Baseline>
      <CommonInformationFields useForm={fc} />
      <DefaultTagsWidget
        title={t('tasks:labels.defaultSuggestions', 'Default suggestions for waste ingredient')}
        onChange={items => setIngredientSuggestions(items)}
        savedItems={saved ? saved.ingredientSuggestions : undefined}
      />
      <DefaultTagsWidget
        savedItems={saved ? saved.defaultSuggestions : undefined}
        onChange={items => fc.setDefaultSuggestions(items)}
        title={t('tasks:labels.reasonsForWaste', 'Default reasons for waste')}
      />
    </Baseline>
  )
  const schedulerBlock = () => (
    <>
      <Toggler
        label={t('tasks:labels.scheduledTask', 'Scheduled task')}
        values={[t('common:togglerValues.no', 'No'), t('common:togglerValues.yes', 'Yes')]}
        initiallyChecked={!!saved && !!saved.schedule}
        name="isScheduledTask"
        onClick={() => {
          fc.toggleShow()
        }}
      />
      {!!fc.scheduledPayload ? (
        <Scheduler
          onChange={fc.handleScheduler}
          schedule={saved ? fc.reverseSchedule(saved.schedule) : undefined}
          lateByDays={saved ? fc.lateByDays : undefined}
          onLateByDaysChange={fc.setLateByDays}
        />
      ) : null}
    </>
  )

  const automationBlock = () => (
    <>
      <Toggler
        label={t('tasks:labels.automatedTask', 'Automated reminder')}
        values={[t('common:togglerValues.no', 'No'), t('common:togglerValues.yes', 'Yes')]}
        initiallyChecked={!!saved && saved.automaticReminder}
        name="isautomaticReminderTask"
        onClick={async () => {
          fc.setAutomaticReminder(!fc.automaticReminder)
        }}
      />
    </>
  )

  const alarmBlock = () => (
    <>
      <Toggler
        label={t('tasks:labels.alarms', 'Alarms')}
        values={[t('common:togglerValues.off', 'Off'), t('common:togglerValues.on', 'On')]}
        initiallyChecked={fc.showAlarm}
        name="isAlarms"
        onClick={async () => {
          fc.setShowAlarm(!fc.showAlarm)
          fc.reset()
          fc.triggerValidation()
        }}
      />
      {fc.showAlarm && (
        <Row childMargin="1rem">
          <LabelInput
            required
            type="number"
            ref={r => fc.register({required: fc.showAlarm})(r)}
            name="maxValue"
            labelText={t('tasks:labels.alarmLimit', 'Alarm limit')}
            errorMsg={
              !!fc.errors.maxValue ? t('common:validation.invalidValue', 'Invalid or missing value') : undefined
            }
          />
          <SmallUnit>{unit}</SmallUnit>
        </Row>
      )}
    </>
  )
  return (
    <MainLayoutWithoutStretch>
      <MainContent variant="white">
        <HeaderRow
          modal={
            isEditing && (
              <TaskMenuModal
                isOpen={fc.modalOpen}
                onClose={() => fc.setModalOpen(false)}
                onDelete={fc.editingDisabled ? undefined : fc.remove}
                onDuplicate={fc.duplicate}
              />
            )
          }
        >
          <TasksGoBackHeaderTitle
            label={
              isEditing
                ? t('tasks:labels.editTask.waste', 'Edit waste task')
                : t('tasks:labels.newTask.waste', 'Add waste task')
            }
            isEditing={isEditing}
          />
          {isEditing && <HeaderActions onMenu={() => fc.setModalOpen(true)} />}
        </HeaderRow>
        <InvisibleContainer>
          <Baseline>
            <Grid>
              <BigLabelInput
                required
                placeholder={t('tasks:labels.newTaskName', 'New task name')}
                name="name"
                id="name"
                labelText={t('tasks:labels.taskName', 'Task name')}
                ref={fc.register({required: `${t('common:validation.requiredField', 'Required field')}`})}
                errorMsg={!!fc.errors.name ? fc.errors.name!.message : undefined}
              ></BigLabelInput>
              <div className="empty"></div>
            </Grid>
            <Grid>
              <Baseline>
                <Select
                  returnId
                  nativeProps={{
                    name: 'taskGroup',
                    onChange(e) {
                      selectTaskGroup(e.target.value)
                    },
                    value: selectedTaskGroup.id
                  }}
                  id="taskGroup"
                  label={t('tasks:taskgroupLabel', 'Task group')}
                  options={taskGroupOptions}
                />
                <Select
                  required
                  returnId
                  ref={fc.register({required: true})}
                  label={t('tasks.labels.taskType', 'Task type')}
                  id="wasteTaskType"
                  nativeProps={{name: 'wasteTaskType', defaultValue: WasteTaskType.WasteAmount}}
                  options={[
                    {
                      option: t('tasks:wasteTaskTypes.wasteAmount', 'Enter waste amount'),
                      id: WasteTaskType.WasteAmount
                    },
                    {
                      option: t('tasks:wasteTaskTypes.customerAmount', 'Enter number of guests'),
                      id: WasteTaskType.CustomerAmount
                    },
                    {
                      option: t('tasks:wasteTaskTypes.productionAmount', 'Enter production amount'),
                      id: WasteTaskType.ProductionAmount
                    }
                  ]}
                />

                {!!fc.form.wasteTaskType ? (
                  fc.form.wasteTaskType === WasteTaskType.CustomerAmount ? (
                    <>{automationBlock()}</>
                  ) : null
                ) : null}

                {!!fc.form.wasteTaskType ? (
                  fc.form.wasteTaskType === WasteTaskType.WasteAmount ? (
                    <>
                      <LabelInput
                        name="target"
                        labelText={t('tasks:labels.wasteCategory', 'Waste category')}
                        id="target"
                        ref={fc.register}
                      />
                      {alarmBlock()}
                      {schedulerBlock()}
                    </>
                  ) : (
                    schedulerBlock()
                  )
                ) : null}
              </Baseline>

              {!!fc.form.wasteTaskType ? infoBlocks() : <div className="empty"></div>}
            </Grid>

            <ButtonRowWrap>
              <div className="empty"></div>
              <ButtonRowGrid>
                <Button name="cancel-button" negative variant="secondary" disabled={fc.loading} onClick={fc.cancel}>
                  {t('common:buttons.cancel', 'Cancel')}
                </Button>
                <Button
                  variant="secondary"
                  disabled={!fc.formState.isValid || fc.loading || rc.list.length === 0 || fc.editingDisabled}
                  onClick={() => (isEditing ? fc.update(taskPayload) : fc.submit(taskPayload))}
                >
                  {t('common:buttons.save', 'Save')}
                </Button>
                <GridSpan span={2}>
                  {fc.editingDisabled && (
                    <P>
                      {t(
                        'tasks:messages.taskInUseMessage',
                        'This task is in use for locations you don’t have access to. You can not edit or delete it but you can duplicate the task to create a new one'
                      )}
                    </P>
                  )}
                </GridSpan>
              </ButtonRowGrid>
            </ButtonRowWrap>
          </Baseline>
        </InvisibleContainer>
      </MainContent>
      <RightSidebar>
        <LocationsList
          controller={rc}
          cyKey={'task'}
          label={t('tasks:labels.selectLocation', 'Select which locations use this task')}
        />
      </RightSidebar>
    </MainLayoutWithoutStretch>
  )
}

export default CreateWasteTask
